<template>
  <div id="stageline-end-page-component">
    <div v-if="loaded" class="stageline-end-page-container">
      <h2>
        Congratulations—you did it!
      </h2>
      <picture v-if="isSlide">
        <source
          :srcset="require('@images/illustrations/stageline/balloon-animation.webp')"
          type="image/webp"
        >
        <img
          :src="require('@images/illustrations/stageline/balloon-animation.gif')"
          alt="Balloon Animation"
          class="balloon-animation"
        >
      </picture>
      <p class="font-weight-bold">
        You’ve reached the end of our business guide.
      </p>
      <p>
        We’re getting ready to launch more business services and resources—check back soon!
      </p>
      <div v-if="isSlide" class="w-100 d-flex flex-row flex-nowrap justify-content-center py-3">
        <b-button
          class="btn btn-preferred"
          aria-label="finished button"
          @click="finished"
        >
          <span class="px-2">
            Finished!
          </span>
        </b-button>
      </div>
    </div>
    <ct-centered-spinner v-else />
  </div>
</template>
<script>
export default {
  name: 'StagelineEndPage',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    isSlide: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loaded: false,
    }
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    async finished() {
      this.loaded = false

      await this.$router.push({
        name: 'root',
      }).catch(()=>{})
    },
  },
}
</script>
<style lang="scss" scoped>
#stageline-end-page-component {
  width: 100%;
  display: flex;
  justify-content: center;

  .stageline-end-page-container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 2em;
      font-weight: 600;
    }

    picture {

      .balloon-animation {
        max-width: 500px;
        padding: 0.8em 0;
      }
    }

    .next-button {
      width: auto;
      min-width: 130px;
      padding: .6rem 0;
    }
  }

  @media only screen and (max-width: 768px) {
    #stageline-end-page-component {
      padding: 1.0em;
    }
  }
}
</style>
